import * as React from 'react'
import useStore from '../../../hooks/useStore'
import { useTranslation } from 'react-i18next'
import { Checkbox, ICheckboxStyles, Text } from 'office-ui-fabric-react'
import CloseIconButton from '../../CloseIconButton'
import AllowAccessSvg from '../svg-icon/AllowAccessSvg'
import UserStore from '../../../stores/userStore'
import { toJS } from 'mobx'
import { authentication } from '@microsoft/teams-js'
import { swalError } from '../../../helpers/swalHepler'
import { mixpanelButtonClickedEvent } from '../../../helpers/mixPanelHelper'
import { ButtonPrimary, ButtonSecondary } from '../../button'
import { lightTheme } from '../../../constants/theme'
import { ThemeProvider } from 'office-ui-fabric-react/lib/Foundation'

const checkboxStyles: ICheckboxStyles = {
  label: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    color: '#313131',
    display: 'flex',
    alignItems: 'center'
  }
}

const PermissionSelectModal = ({
  onClose,
  isCalendarOptionCheckedByDefault = false,
  isUserOptionCheckedByDefault = false,
  openFrom = ''
}) => {
  const { userStore }: { userStore: UserStore } = useStore()
  const { t } = useTranslation()
  const scopes = toJS(userStore.scopes)
  const isCalendarReadSharedAlreadyGranted = scopes.includes('Calendars.Read.Shared')
  const isPeopleReadGrantedAlreadyGranted = scopes.includes('People.Read')
  const isUserReadBasicAllAlreadyGranted = scopes.includes('User.ReadBasic.All')

  const [isCalendarReadSharedGrantedState, setIsCalendarReadSharedGranted] = React.useState(
    isCalendarReadSharedAlreadyGranted || isCalendarOptionCheckedByDefault
  )
  // both calendar and user permissions are need people.read so thats why we have this logic
  const [isPeopleReadGrantedState, setIsPeopleReadGranted] = React.useState(
    isPeopleReadGrantedAlreadyGranted || isCalendarOptionCheckedByDefault || isUserOptionCheckedByDefault
  )
  const [isUserReadBasicAllGrantedState, setIsUserReadBasicAllGranted] = React.useState(
    isUserReadBasicAllAlreadyGranted || isUserOptionCheckedByDefault
  )

  const isUserReadAllChecked = isUserReadBasicAllGrantedState && isPeopleReadGrantedState
  const isCalendarReadSharedChecked = isCalendarReadSharedGrantedState && isPeopleReadGrantedState

  // check the options if already granted don't even render
  const isCalendarOptionAlreadyGranted = isCalendarReadSharedAlreadyGranted && isCalendarReadSharedAlreadyGranted
  const isUserOptionAlreadyGranted = isUserReadBasicAllAlreadyGranted && isPeopleReadGrantedAlreadyGranted

  const handleContinue = () => {
    mixpanelButtonClickedEvent('Permission select modal continue button', openFrom)
    const hash = window.location.hash

    const stateObj = {
      fromAllowAccess: true
    }

    if (hash) {
      stateObj['state'] = hash
    }

    let redirectState = hash ? '&state=' + encodeURIComponent(JSON.stringify(stateObj)) : ''
    const newScopes = []

    if (isCalendarReadSharedGrantedState) {
      newScopes.push('Calendars.Read.Shared')
    }

    if (isPeopleReadGrantedState) {
      newScopes.push('People.Read')
    }

    if (isUserReadBasicAllGrantedState) {
      newScopes.push('User.ReadBasic.All')
    }

    // remove duplicates and encode the scopes to be used in the url keep basic scopes add user's scopes and the new scopes
    const currentScopes = encodeURIComponent(
      Array.from(
        new Set(
          // it comes from the env file user.read%20calendars.read.shared
          process.env.APP_BASIC_SCOPES.split('%20')
            .concat(newScopes)
            .concat(scopes) // ['user.read', 'calendars.read.shared', 'User.Read']
            .join(' ')
            .toLocaleLowerCase()
            .split(' ')
        )
      ).join(' ') // user.read calendars.read.shared
    ) // user.read%20calendars.read.shared

    if (userStore.platform === 'teams') {
      authentication.authenticate({
        url: window.location.origin + '/authorize?inTeams=true&scope=' + currentScopes,
        width: 600,
        height: 535,
        successCallback: result => {
          // reset users localstorage cache its new login so we need to refresh the cache
          userStore.removeLocalStorageData()
          // refresh the page to get the new token
          window.location.href = window.location.origin + '/?inTeams=true/' + `#/${hash}`
        },
        failureCallback: reason => {
          // there should't be a reason to fail and user is already signed in so just reload the page and say update wasn't successful
          swalError(t('PermissionSelectModal_updateFailed', 'Update was not successful. Please try again.'))
        }
      })
    } else {
      // redirect to the next page
      window.location.href =
        'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=' +
        process.env.APP_ID +
        '&redirect_uri=' +
        window.location.origin +
        '/authorize' +
        '&response_type=code&prompt=select_account&scope=' +
        currentScopes +
        redirectState
    }
  }

  const renderCalendarPermissionCheckbox = () => {
    if (isCalendarOptionAlreadyGranted) {
      return null
    }
    return (
      <Checkbox
        label={t('PermissionSelectModal_access_to_your_calendar', 'Access to you calendar')}
        styles={checkboxStyles}
        onChange={(ev, checked) => {
          mixpanelButtonClickedEvent('Permission select modal calendar permission checkbox', openFrom)
          setIsCalendarReadSharedGranted(checked)
          if (isUserReadBasicAllGrantedState && !checked) {
            return
          }
          setIsPeopleReadGranted(checked)
        }}
        checked={isCalendarReadSharedChecked}
      />
    )
  }

  const renderUserPermissionCheckbox = () => {
    if (isUserOptionAlreadyGranted) {
      return null
    }

    return (
      <Checkbox
        label={t(
          'PermissionSelectModal_access_to_your_organization_directory',
          `Access basic user information in your organization`
        )}
        styles={checkboxStyles}
        onChange={(ev, checked) => {
          mixpanelButtonClickedEvent('Permission select modal user permission checkbox', openFrom)
          setIsUserReadBasicAllGranted(checked)
          if (!checked && isCalendarReadSharedGrantedState) {
            return
          }
          setIsPeopleReadGranted(checked)
        }}
        checked={isUserReadAllChecked}
      />
    )
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 24
          }}
        >
          <Text
            style={{
              color: '#313131',
              fontSize: 20,
              fontWeight: 600,
              lineHeight: '28px',
              textAlign: 'left'
            }}
          >
            {t('PermissionSelectModal_selectPermissions', 'Select permissions')}
          </Text>

          <CloseIconButton onClick={onClose} />
        </div>

        {/* body */}
        <div
          style={{
            gap: 16,
            padding: 24,
            paddingTop: 0,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <AllowAccessSvg />
          </div>

          {/* To help you get the most out of Teamflect, please select the permissions you'd like to enable.  */}
          <Text
            style={{
              color: '#313131',
              fontSize: 14,
              lineHeight: '20px',
              fontWeight: 400
            }}
          >
            {t(
              'PermissionSelectModal_permissionText',
              "To help you get the most out of Teamflect, please select the permissions you'd like to enable."
            )}
          </Text>

          {renderUserPermissionCheckbox()}
          {renderCalendarPermissionCheckbox()}

          {/* Your information is protected and used solely to enhance connectivity and efficiency within your organization. */}
          <Text
            style={{
              color: '#313131',
              fontSize: 12,
              lineHeight: '16px',
              fontWeight: 400
            }}
          >
            {t(
              'PermissionSelectModal_informationText',
              'Your information is protected and used solely to enhance connectivity and efficiency within your organization.'
            )}
          </Text>

          <div
            style={{
              display: 'flex',
              gap: 8,
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <ButtonSecondary
              style={{
                padding: '8px 12px'
              }}
              onClick={onClose}
              content={t('Cancel', 'Cancel')}
            />

            <ButtonPrimary
              style={{
                padding: '8px 12px'
              }}
              onClick={handleContinue}
              disabled={
                !(
                  (!isCalendarOptionAlreadyGranted && isCalendarReadSharedChecked) ||
                  (!isUserOptionAlreadyGranted && isUserReadAllChecked)
                )
              }
              content={t('Continue', 'Continue')}
            />
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export { PermissionSelectModal }
