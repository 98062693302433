import * as moment from 'moment'
import { USER_ROLE } from '../constants/userConstants'

const hubspotIdentifyUser = (userData, inTeams) => {
  if (!userData.mail) {
    return
  }

  // we only want to identify admins who are the first to join the tenant or first five users
  //const role = userData.role
  //const signUpRank = userData.signUpRank ?? -1

  //if (
  //  (role === USER_ROLE.GlobalAdministrator || (signUpRank >= 0 && signUpRank <= 5)) &&
  //  moment(userData.tenant.createdAt).isAfter(moment().subtract(5, 'minute'))
  //) {
    let _hsq = (window._hsq = window._hsq || [])
    _hsq.push([
      'identify',
      {
        email: userData.mail
      }
    ])
    _hsq.push(['setPath', '/#/home'])
    _hsq.push(['trackPageView'])
  //}
}

export { hubspotIdentifyUser }
