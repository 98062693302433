import * as React from 'react'
import { createContext } from 'react'
import * as ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons'
import { Provider } from 'mobx-react'
import stores from './stores/index'
//const Progress = React.lazy(() => import('./components/Progress'));
//import * as microsoftTeams from '@microsoft/teams-js'
import { app, Context, teamsCore } from '@microsoft/teams-js'
import * as Sentry from '@sentry/browser'
import 'draft-js/dist/Draft.css'
import './styles.less'
import SuspanseFallbackPage from './pages/SuspanseFallbackPage'
import { checkError } from './helpers/filterErrorHelperClient'
import { PersonaContextProvider } from './context/PersonaContextProvider'
import { AppContext } from './context/AppContext'
import './i18n'
import { MeetingContextProvider } from './context/MeetingContextProvider'
import { ReviewContextProvider } from './context/ReviewContextProvider'

//import App from './App';
const App = React.lazy(() => import('./App'))

// Teamflect CDN causes random CORS errors when loading font files
// initializeIcons('https://teamflectcdn.teamflect.com/teamflect/fabric-icons/')
initializeIcons('https://res.cdn.office.net/files/fabric-cdn-prod_20241209.001/assets/icons/')
app.initialize()

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f0ecba485a5146cc9684969874b7aa34@sentry.io/1814786',
    beforeSend(event, hint) {
      const error = hint.originalException
      return checkError(error, event)
    },
    environment: process.env.ENVIRONMENT === 'prod' ? 'production' : process.env.ENVIRONMENT,
    ignoreErrors: [
      'Non-Error promise rejection captured', // Raises when app opened via mail link in Outlook
      'Non-Error exception captured', // Raises when app opened via mail link in Outlook
      'ResizeObserver loop completed with undelivered notifications' // Common error
    ]
  })
}
const render = Component => {
  ReactDOM.render(
    // @ts-ignore
    <AppContainer>
      {/*@ts-ignore*/}
      <Provider {...stores}>
        <AppContext.Provider value={stores}>
          <PersonaContextProvider>
            <MeetingContextProvider>
              <ReviewContextProvider>
                <React.Suspense fallback={<SuspanseFallbackPage />}>
                  <Component />
                </React.Suspense>
              </ReviewContextProvider>
            </MeetingContextProvider>
          </PersonaContextProvider>
        </AppContext.Provider>
      </Provider>
    </AppContainer>,
    document.getElementById('container') || document.createElement('div') // second parameter added for test purposes!!
  )
}

/* Render application after Office initializes */
//@ts-ignore
//if (Office) {Office.onReady();}

/* Initial render showing a progress bar */
render(App)

if ((module as any).hot) {
  ;(module as any).hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}
declare global {
  interface Window {
    Intercom?: any
    Beacon?: any
    // HubSpot
    _hsq?: any
  }
}
